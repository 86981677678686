<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="auto">
        <v-card>
          <v-card-title class="py-1">Tipo de cuadro</v-card-title>
          <v-card-text>
            <v-radio-group v-model="tipos_reporte" hide-details class="mt-0">
              <v-radio
                v-for="n in items_tipos_reporte"
                :key="n.valor"
                :label="n.texto"
                :value="n.valor"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="py-1">Filtros</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" align-self="center">
                <v-text-field
                  v-model="fecha_desde"
                  label="Desde"
                  type="date"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align-self="center">
                <v-text-field
                  v-model="fecha_hasta"
                  label="Hasta"
                  type="date"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" align-self="center">
                <v-autocomplete
                  v-model="list_clientes_seleccionados"
                  :items="items_clientes"
                  item-text="nombre"
                  item-value="id_cliente"
                  label="Asegurado"
                  outlined
                  dense
                  hide-details
                  multiple
                  chips
                  small-chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" align-self="center">
                <v-autocomplete
                  v-model="list_aseguradoras_seleccionadas"
                  :items="items_aseguradoras"
                  item-text="descripcion"
                  item-value="id_aseguradoras"
                  label="Aseguradoras"
                  outlined
                  dense
                  hide-details
                  multiple
                  chips
                  small-chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-btn color="grey" dark @click="listarCuadros()">Descargar</v-btn>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-progress-circular
          v-if="progress_circular"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="3" class="mx-auto" v-if="list_items == 0">
        <span class="title">No hay resultados</span>
      </v-col>
      <v-col cols="3" class="mx-auto" v-if="list_items != 0">
        <span class="title"
          >Se encontraron {{ list_items.length }} resultados</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
var XLSX = require("xlsx");
import moment from "moment";

export default {
  data: () => ({
    tipos_reporte: "polizas_iniciales",
    items_tipos_reporte: [
      { texto: "Polizas iniciales", valor: "polizas_iniciales" },
      {
        texto: "Cancelaciones y exclusiones",
        valor: "cancelaciones_exclusiones",
      },
    ],
    fecha_desde: "",
    fecha_hasta: "",
    filtro_seleccionado: [],
    list_items: [],
    items_clientes: [],
    list_clientes_seleccionados: [],
    items_aseguradoras: [],
    list_aseguradoras_seleccionadas: [],
    progress_circular: false,
  }),
  created() {
    this.listarClientes();
    this.listarAseguradoras();
  },
  methods: {
    listarClientes() {
      axios.get("api/Clientes/Listar").then((response) => {
        //console.log(response.data);

        this.items_clientes = response.data;
      });
    },
    listarAseguradoras() {
      axios.get("api/Aseguradoras/Listar").then((response) => {
        //console.log(response.data);

        this.items_aseguradoras = response.data;
      });
    },
    listarCuadros() {
      if (this.fecha_desde == "" || this.fecha_hasta == "") {        
        this.alerta("info", "Los campos de fecha son obligatorio")
        return;
      }

      this.progress_circular = true;
      axios
        .post("api/Polizas/ListarPorCuadros", {
          id_cliente: this.list_clientes_seleccionados,
          id_aseguradora: this.list_aseguradoras_seleccionadas,
          desde: this.fecha_desde == "" ? new Date() : this.fecha_desde,
          hasta: this.fecha_hasta == "" ? new Date() : this.fecha_hasta,
        })
        .then(async (response) => {
          //console.log(response.data);
          this.list_items = [];
          if (this.tipos_reporte == "polizas_iniciales") {
            for (var i = 0; i < response.data.length; i++) {
              if (response.data[i].inicial_renovacion == "Es poliza inicial") {
                var result = await (
                  await axios.get(
                    "api/Cuotas/Listar/" + response.data[i].id_polizas
                  )
                ).data;

                this.list_items.push({
                  Asegurado: response.data[i].clientes.nombre,
                  Aseguradora:
                    response.data[i].seguros.aseguradoras.descripcion,
                  "Tipo de póliza": response.data[i].seguros.nombre,
                  "Número de póliza": response.data[i].num_poliza,
                  "Ejecutivo asignado": response.data[i].ejecutivo,
                  "Fecha desde": this.formatoFecha(
                    response.data[i].fecha_inicial
                  ),
                  "Prima comisionable": response.data[i].prima_comisionable,
                  "% De comisión": response.data[i].comision,
                  "Comisión vigencia completa":
                    response.data[i].prima_comisionable *
                    (response.data[i].comision / 100),
                  "Numero de cuotas": result.length,
                });
              }
            }
            this.progress_circular = false;
            this.generarExcel(this.list_items);
          } else if (this.tipos_reporte == "cancelaciones_exclusiones") {
            for (var i = 0; i < response.data.length; i++) {
              if (!response.data[i].activo) {
                this.list_items.push({
                  Asegurado: response.data[i].clientes.nombre,
                  Aseguradora:
                    response.data[i].seguros.aseguradoras.descripcion,
                  "Tipo de póliza": response.data[i].seguros.nombre,
                  "Número de póliza": response.data[i].num_poliza,
                  "Ejecutivo asignado": response.data[i].ejecutivo,
                  "Fecha de cancelación": this.formatoFecha(
                    response.data[i].fecha_cancelacion
                  ),
                  Motivo: response.data[i].motivo,
                  "Prima comisionable": response.data[i].prima_comisionable,
                  "% De comisión": response.data[i].comision,
                  "Comisión vigencia completa":
                    response.data[i].prima_comisionable *
                    (response.data[i].comision / 100),
                });
              }
            }
            this.progress_circular = false;
            this.generarExcel(this.list_items);
          }
        });
    },
    generarExcel(excel_data) {
      if (excel_data.length == 0) {
        return;
      }

      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    formatoFecha(fecha) {
      if (!fecha) {
        return "";
      }
      return moment(fecha).format("YYYY-MM-DD");
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>

<style scoped>
.borde {
  border: 1px black solid;
}
</style>